import baseComponent from "@/scripts/baseComponent";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import { API_CALL_STATUS } from "@/constant/config";
import FixedRentalSettings from "../../../views/components/FixedRental/FixedRentalSettings";
import EventBus from "@/event/EventBus";
import moment from "moment";

export default {

    extends: baseComponent,

    components: {
        FixedRentalSettings,
    },

    props: {
        listData: {
            type: Array,
            default: []
        },
        fixedRentalId: {
            type: Number,
            default: 0
        },
    },

    data() {
        return {

        };
    },

    computed: {

    },

    created() {

    },

    mounted() {

    },

    methods: {

        // Back
        back() {
            this.$emit("processingEvent", { event: "processingPage", data: "selection" });
        },

        // Add
        add() {
            let request = {
                listData: this.listData,
                fixedRentalId: this.fixedRentalId,
                deviceInformation: JSON.stringify(this.getInformationClient()),
            }
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            fixedRentalApi.addImage(request)
                .then((res) => {
                    try {
                        this.postAddImageSuccess();
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            // if (this.$route.path !== '/login') {
                            //     setTimeout(() => {
                            //         location.href = "/login";
                            //     }, 2000);
                            // }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },

        // POST add image __ SUCCESS
        postAddImageSuccess() {
            setTimeout(() => {
                this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                this.$emit("processingEvent", { event: "processingPage", data: "list" });
                this.notificationSuccess(
                    "Success !",
                    "The changes have been applied successfully"
                );
                EventBus.$emit("fixed-rental-perform-action", { action: 'refresh-silent' });
            }, 500);
        },
    }
} 