import baseComponent from "@/scripts/baseComponent";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import { API_CALL_STATUS } from "@/constant/config";
import EventBus from "@/event/EventBus";

export default {

    extends: baseComponent,

    props: {
        username: {
            type: String,
            default: null
        },
        fixedRentalId: {
            type: Number,
            default: 0
        },
        fixedRentalDetailId: {
            type: Number,
            default: 0
        },
    },

    data() {
        return {
            listData: [],
            defaultData: [],
            filterValue: null,
        };
    },

    computed: {

    },

    created() {

    },

    mounted() {
        this.getData();
    },

    methods: {

        // Filter list
        filterList() {
            this.listData = this.defaultData.filter(x => x.machineId.toString().includes(this.filterValue));
            this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
        },

        // Back detais
        back() {
            this.$emit("processingEvent", { event: "processingPage", data: "list" });
        },

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData();
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get list image
        getData() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            fixedRentalApi.getMachineListByUsername({ username: this.username, fixedRentalId: this.fixedRentalId })
                .then((res) => {
                    try {
                        this.defaultData = res.data;
                        this.listData = res.data;
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            // if (this.$route.path !== '/login') {
                            //     setTimeout(() => {
                            //         location.href = "/login";
                            //     }, 2000);
                            // }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },

        // Change image
        change(element) {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            let request = {
                machineId: element.machineId,
                fixedRentalDetailId: this.fixedRentalDetailId,
                deviceInformation: JSON.stringify(this.getInformationClient())
            }
            fixedRentalApi.fixedRentalChangeImage(request)
                .then((res) => {
                    try {
                        this.postChangeImageSuccess();
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    // location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },

        // POST change image __ SUCCESS
        postChangeImageSuccess() {
            setTimeout(() => {
                this.$emit("processingEvent", { event: "processingPage", data: "list" });
                this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                this.notificationSuccess(
                    "Success !",
                    "The changes have been applied successfully"
                );
                EventBus.$emit("fixed-rental-perform-action", { action: 'refresh-silent' });
            }, 500);
        },
    }
} 