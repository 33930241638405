import baseComponent from "@/scripts/baseComponent"; 
import { FUNCTION_CODE, API_CALL_STATUS } from "@/constant/config";
import fixedRentalApi from '@/api/gpuHub/fixedRental'; 
import { mapState } from "vuex";
import commonActionHandle from "@/commonActionHandle";
import EventBus from "@/event/EventBus"; 
import CaretTop from "../../../views/components/Icon/CaretTop.vue";
import CaretBottom from "../../../views/components/Icon/CaretBottom.vue"; 
const queryString = require('query-string');
import moment from "moment"; 

export default {

    extends: baseComponent,

    components: {
        CaretTop,
        CaretBottom,
    },
    watch: {
        isShowDeactiveOptions(val){
            if(!val){
                this.deactiveMachineData = null;
                this.deactiveMachineOption = 0;
            }
        }
    },
    data() {
        return {
            isCheckAll: false,
            listData: [],
            pagination: {
                querySearch: '',
                orderBy: 'A.LAST_MODIFIED_AT',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 20,
                outRowsNumber: 0,
            },
            isShowDeactiveOptions: false,
            deactiveMachineData: null,
            deactiveMachineOption: 0,
        };
    },

    props: {
        fixedRentalId: {
            type: Number,
            default: null
        },
    },

    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },

    created() {
        this.getData(this.pagination);
    },

    methods: {
        getLastModifiedByText(lastModifiedBy){
            if(lastModifiedBy === "Fixed rental service") return "Automatically by service";
            if(lastModifiedBy.includes("@irender.vn")) return "Manual by admin";
            return "Manual by user";
        },
        getLastModifiedByTextColor(lastModifiedBy){
            if(lastModifiedBy === 'Fixed rental service') return 'forestgreen';
            if(lastModifiedBy.includes("@irender.vn")) return "darkorange";
            return "#42a5f5";
        },
        // Processing page
        processingPage(page) {
            this.$emit("processingEvent", { event: "processingPage", data: page });
        },

        // Set list image modify
        setListData(list) {
            this.$emit("processingEvent", { event: "setListData", data: list });
        },

        // Selected all item
        selectedAll() {
            this.listData.forEach(element => {
                element.isChecked = this.isCheckAll;
            });
        },

        // Selected item
        selectedItem() {
            if (this.listData.filter(x => x.isChecked).length === this.listData.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },

        // Add
        add() {
            this.processingPage('add')
        },

        // Modify
        modify(listModify) {
            this.setListData(listModify);
            this.processingPage('modify')
        },

        // Change
        change(param) {
            this.machineIdNew = param;
            this.processingPage('changeAction')
        },

        //Context menu action
        contextMenuAction(command) {
            switch (command.type) {
                case "modify":
                    this.setListData([command.data]);
                    this.processingPage(command.type)
                    break;
                case "change":
                    this.$emit("processingEvent", { event: "setFixedRentalDetailsId", data: command.data.fixedRentalDetailId });
                    this.processingPage(command.type)
                    break;
                case "deactivate":{
                        //const isTestUser = this.$testUserEmail.includes(this.userInfo.userEmail.toLowerCase());
                        const isExpired = command.data.isExpired;
                        const rentalPlanEndtime = moment.utc(command.data.endTime);
                        const currentTime = moment.utc();
                        const isExpiredExact = isExpired === 1 || currentTime.isSameOrAfter(rentalPlanEndtime); 
                        if(isExpiredExact) this.changeStatus({ status: 0, data: [command.data] })
                        else {
                            this.deactiveMachineData = command.data;
                            this.deactiveMachineOption = 0;
                            this.isShowDeactiveOptions = true;
                        }
                    }
                    break;
                case "activate":
                    this.changeStatus({ status: 1, data: [command.data] })
                    break;
                case "continue":
                    this.settings({ continueRunning: true, data: [command.data] })
                    break;
                case "shutdown":
                    this.settings({ continueRunning: false, data: [command.data] })
                    break;
                case "remove":
                    this.remove([command.data])
                    break;
            }
        }, 
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get data
        getData() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            this.isCheckAll = false;
            this.pagination.fixedRentalId = this.fixedRentalId;
            fixedRentalApi.getFixedRentalDetails(queryString.stringify(this.pagination))
                .then((res) => {
                    try {
                        let responseData = res.data;
                        let pagingItem = res.data.pagingItem;
                        responseData.data.forEach(element => {
                            element.isChecked = false;
                        });
                        this.listData = responseData.data;
                        this.pagination.orderBy = pagingItem.orderBy;
                        this.pagination.directionSort = pagingItem.directionSort;
                        this.pagination.pageIndex = pagingItem.pageIndex;
                        this.pagination.pageSize = pagingItem.pageSize;
                        this.pagination.outRowsNumber = pagingItem.outRowsNumber;
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },

        // Search list
        searchList() {
            this.pagination.querySearch;
            this.getData();
        },

        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1;
            this.pagination.orderBy = element;
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData();
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element;
            this.getData();
        },

        // Change status
        changeStatus(param) {
            commonActionHandle.showCustomConfirm(
                'Confirm !',
                param.status === 1 ? `Are you sure you want to active ${param.data.length} image?` : `Are you sure you want to deactivate ${param.data.length} images?`,
                () => {
                    this.feedStatusLoading(API_CALL_STATUS.LOADING);
                    let request = {
                        status: param.status,
                        listData: param.data,
                        targetAction: param.status === 0 ? this.deactiveMachineOption : null,
                        deviceInformation: JSON.stringify(this.getInformationClient()),
                    }; 
                    fixedRentalApi.changeStatus(request)
                        .then(() => {
                            try {
                                this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                                this.performAction({ action: 'refresh' });
                                EventBus.$emit("fixed-rental-perform-action", { action: 'refresh-silent' });
                                this.isShowDeactiveOptions = false;
                            } catch (error) {
                                this.$store.dispatch("common/showUnkownError", error, this.$lang.dialog.cloneImageFailed);
                            }
                        })
                        .catch(error => {
                            try {
                                if (error.name === 'Error') {
                                    this.$store.dispatch("common/showCommonError", error);
                                } else {
                                    this.$store.dispatch("common/showUnkownError", error, "Error");
                                }
                            } catch (error) {
                                this.$store.dispatch("common/showUnkownError", error, "Error");
                            }
                            this.feedStatusLoading(API_CALL_STATUS.FAILED);
                        });
                }
            );
        },

        confirmDeactiveRentalPlan(){
            this.changeStatus({ status: 0, data: [this.deactiveMachineData] })
        },

        // Settings
        settings(param) {
            commonActionHandle.showCustomConfirm(
                'Confirm !',
                param.continueRunning ? `Are you sure you want to setting fixed rental ${param.data.length} image to <strong>continue running this machine and calculate the hourly rate after it expires ?</strong>` : `Are you sure you want to settings fixed rental ${param.data.length} image <strong>shutdown after it expires</strong> ?`,
                () => {
                    this.feedStatusLoading(API_CALL_STATUS.LOADING);
                    let request = {
                        continueRunning: param.continueRunning,
                        listData: param.data,
                        deviceInformation: JSON.stringify(this.getInformationClient()),
                    };
                    fixedRentalApi.settings(request)
                        .then((res) => {
                            try {
                                this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                                this.performAction({ action: 'refresh' });
                                EventBus.$emit("fixed-rental-perform-action", { action: 'refresh-silent' });
                            } catch (error) {
                                this.$store.dispatch("common/showUnkownError", error, this.$lang.dialog.cloneImageFailed);
                            }
                        })
                        .catch(error => {
                            try {
                                if (error.name === 'Error') {
                                    this.$store.dispatch("common/showCommonError", error);
                                } else {
                                    this.$store.dispatch("common/showUnkownError", error, "Error");
                                }
                            } catch (error) {
                                this.$store.dispatch("common/showUnkownError", error, "Error");
                            }
                            this.feedStatusLoading(API_CALL_STATUS.FAILED);
                        });
                }
            );
        },

        // Remove
        remove(data) {
            commonActionHandle.showCustomConfirm(
                'Confirm !',
                `Are you sure you want to remove ${data.length} image?`,
                () => {
                    this.feedStatusLoading(API_CALL_STATUS.LOADING);
                    let request = {
                        listData: data,
                        deviceInformation: JSON.stringify(this.getInformationClient()),
                    };
                    fixedRentalApi.remove(request)
                        .then((res) => {
                            try {
                                this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                                this.performAction({ action: 'refresh' });
                                EventBus.$emit("fixed-rental-perform-action", { action: 'refresh-silent' });
                            } catch (error) {
                                this.$store.dispatch("common/showUnkownError", error, this.$lang.dialog.cloneImageFailed);
                            }
                        })
                        .catch(error => {
                            try {
                                if (error.name === 'Error') {
                                    this.$store.dispatch("common/showCommonError", error);
                                } else {
                                    this.$store.dispatch("common/showUnkownError", error, "Error");
                                }
                            } catch (error) {
                                this.$store.dispatch("common/showUnkownError", error, "Error");
                            }
                            this.feedStatusLoading(API_CALL_STATUS.FAILED);
                        });
                }
            );
        }
    }
}