import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import { API_CALL_STATUS } from "@/constant/config";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import moment from "moment";
import DetailsFixedRentalList from "../../views/components/FixedRental/DetailsFixedRentalList";
import DetailsFixedRentalModify from "../../views/components/FixedRental/DetailsFixedRentalModify";
import ChangeImageFixedRental from "../../views/components/FixedRental/ChangeImageFixedRental";
import AddImageFixedRental from "../../views/components/FixedRental/AddImageFixedRental";
const queryString = require('query-string');

export default {

    extends: baseComponent,

    components: {

        DetailsFixedRentalList,
        DetailsFixedRentalModify,
        AddImageFixedRental,
        ChangeImageFixedRental,
    },

    data() {
        return {
            isCheckAll: false,
            listData: [],
            pagination: {
                querySearch: '',
                orderBy: 'A.LAST_MODIFIED_AT',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 20,
                outRowsNumber: 0,
            },

            step: 'list',
            detailsFixedRentalListKey: 0,
            detailsFixedRentalModifyKey: 0,
            changeImageFixedRentalKey: 0,
            addImageFixedRentalKey: 0,
            listModify: [],

            machineIdOld: null,
            machineIdNew: null,
            fixedRentalDetailId: null,

        };
    },

    props: {
        fixedRentalId: {
            type: Number,
            default: null
        },
        username: {
            type: String,
            default: null
        },
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },

    created() {

    },

    methods: {

        // Processing event
        processingEvent(param) {
            this[param.event](param.data);
        },

        // Processing page
        processingPage(page) {
            switch (page) {
                case 'list':
                    this.detailsFixedRentalListKey++;
                    break;
                case 'modify':
                    this.detailsFixedRentalModifyKey++;
                    break;
                case 'change':
                    this.fixedRentalChangeImageKey++;
                    break;
                case 'add':
                    this.changeImageFixedRentalKey++;
                    break;
            }
            this.step = page;
        },

        // Set list image modify
        setListData(list) {
            this.listModify = list;
        },

        // Set fixed rental details ID
        setFixedRentalDetailsId(id) {
            this.fixedRentalDetailId = id;
        }
    }
}