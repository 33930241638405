import baseComponent from "@/scripts/baseComponent";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import { API_CALL_STATUS } from "@/constant/config";
import moment from "moment";
import AddImageFixedRentalSelection from "../../../views/components/FixedRental/AddImageFixedRentalSelection";
import AddImageFixedRentalSettings from "../../../views/components/FixedRental/AddImageFixedRentalSettings";

export default {

    extends: baseComponent,

    components: {
        AddImageFixedRentalSelection,
        AddImageFixedRentalSettings,
    },

    props: {
        username: {
            type: String,
            default: null
        },
        fixedRentalId: {
            type: Number,
            default: 0
        },
    },

    data() {
        return {
            step: 'selection',
            listData: []
        };
    },

    computed: {

    },

    created() {

    },

    mounted() {

    },

    methods: {

        // Processing event
        processingEvent(param) {
            this[param.event](param.data);
        },

        // Set list image selected
        setListData(list) {
            this.listData = list;
            this.processingPage('settings');
        },

        // Processing page
        processingPage(page) {
            switch (page) {
                case 'list':
                    this.$emit("processingEvent", { event: "processingPage", data: page });
                    break;
                case 'selection':
                    // this.detailsFixedRentalListKey++;
                    break;
                case 'settings':
                    // this.detailsFixedRentalModifyKey++;
                    break;
            }
            this.step = page;
        },
    }
} 