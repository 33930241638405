import baseComponent from "@/scripts/baseComponent";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import { API_CALL_STATUS } from "@/constant/config";
import moment from "moment";
import FixedRentalAdd from "../../../views/components/FixedRental/FixedRentalAdd";

export default {

    extends: baseComponent,

    components: {
        FixedRentalAdd,
    },

    props: {
        username: {
            type: String,
            default: null
        },
        fixedRentalId: {
            type: Number,
            default: 0
        },
    },

    data() {
        return {
            defaultData: [],
            listData: []
        };
    },

    computed: {

    },

    created() {

    },

    mounted() {
        this.getData();
    },

    methods: {

        // Processing event
        processingEvent(param) {
            this[param.event](param.data);
        },

        // Back
        back() {
            this.$emit("processingEvent", { event: "processingPage", data: "list" });
        },

        // Next
        next() {
            this.$emit("processingEvent", { event: "setListData", data: this.listData.filter(x => x.isChecked) });
        },

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData();
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get list image
        getData() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            fixedRentalApi.getMachineListByUsername({ username: this.username, fixedRentalId: this.fixedRentalId })
                .then((res) => {
                    try {
                        res.data.forEach(element => {
                            element.activated = true;
                            element.isChecked = false;
                            element.startTime = moment();
                            element.endTime = moment(element.startTime).add(7, 'days')
                        });
                        this.defaultData = res.data;
                        this.listData = res.data;
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            // if (this.$route.path !== '/login') {
                            //     setTimeout(() => {
                            //         location.href = "/login";
                            //     }, 2000);
                            // }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },
    }
} 