import baseComponent from "@/scripts/baseComponent";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import { mapState } from "vuex";
import moment from 'moment';
import FixedRentalSettings from "../../../views/components/FixedRental/FixedRentalSettings";
import { API_CALL_STATUS } from "@/constant/config";
import EventBus from "@/event/EventBus";

export default {

    components: {
        FixedRentalSettings,
    },

    extends: baseComponent,

    props: {
        listModify: {
            type: Array,
            default: []
        },
    },

    data() {
        return {
            startTime: moment(),
            endTime: moment().add(7, 'days'),
        };
    },

    computed: {
        ...mapState({
            listServicePack: (state) => state.common.listServicePack,
        }),
    },

    created() {

    },

    mounted() {

    },

    methods: {

        // Back
        back() {
            this.processingPage('list');
        },

        // Processing page
        processingPage(page) {
            this.$emit("processingEvent", { event: "processingPage", data: page });
        },

        // Apply settings
        applySettings() {
            let request = {
                listData: this.listModify,
                deviceInformation: JSON.stringify(this.getInformationClient()),
            }
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            fixedRentalApi.fixedRentalEdit(request)
                .then((res) => {
                    try {
                        this.postApplySuccess();
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            // if (this.$route.path !== '/login') {
                            //     setTimeout(() => {
                            //         location.href = "/login";
                            //     }, 2000);
                            // }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },

        // POST apply __ SUCCESS
        postApplySuccess() {
            setTimeout(() => {
                this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                this.$emit("processingEvent", { event: "processingPage", data: "list" });
                this.notificationSuccess(
                    "Success !",
                    "The changes have been applied successfully"
                );
                EventBus.$emit("fixed-rental-perform-action", { action: 'refresh-silent' });
            }, 500);
        },
    }
} 